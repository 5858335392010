export const english = {
  PASSENGER_TYPE_ADULT_DISPLAY: 'Adult',
  PASSENGER_TYPE_ADULT_NAME: 'Adult',
  PASSENGER_TYPE_ADULT_INFO: `You are an adult if you are over 18 years old and you do not fall in the categories that affords you a discount, such as student, senior or disabled persons.`,
  PASSENGER_TYPE_ADULT_RESTRICTIONS: [],
  PASSENGER_TYPE_INFANT_DISPLAY: 'Children 0-5 years',
  PASSENGER_TYPE_INFANT_NAME: 'Children 0-5',
  PASSENGER_TYPE_INFANT_INFO: `Children 0-5 years applies to anyone under 6 years of age on the day of departure. Children in this category cannot travel alone and must be accompanied by a person aged 13 years or over. Generally, we recommend that young children are accompanied by an adult. Add-ons cannot be tied to a ticket for children 0-5 years.`,
  PASSENGER_TYPE_INFANT_RESTRICTIONS: [],
  PASSENGER_TYPE_SENIOR_DISPLAY: 'Senior and disabled persons',
  PASSENGER_TYPE_SENIOR_NAME: 'Senior and disabled persons',
  PASSENGER_TYPE_SENIOR_INFO: `If you are over 67 years of age, receive disability or are blind, deaf-blind or visually impaired, you can purchase a discounted ticket for senior or disabled persons. You also qualify if you are the registered partner or spouse of a person who qualify, and you are travelling together. Remember that you must be able to document that you meet the requirements.  Photo ID can be used to document age. Other valid documentation is issued by NAV, Norges Blindeforbund (NABP) or Kompetansesenteret for døvblinde NKDB.`,
  PASSENGER_TYPE_SENIOR_RESTRICTIONS: [],
  PASSENGER_TYPE_ATTENDANT_DISPLAY: 'Are you travelling with a companion?',
  PASSENGER_TYPE_ATTENDANT_NAME: 'Companion',
  PASSENGER_TYPE_ATTENDANT_INFO: `Companions for disabled persons travel for free with appropriate documentation. Valid documentation is:`,
  PASSENGER_TYPE_ATTENDANT_RESTRICTIONS: [
    'Nasjonalt ledsagerbevis',
    'Evidence from Norges blindeforbund (NABP)',
    'Evidence from Kompetansesenter for døvblinde (NKBD)',
  ],
  PASSENGER_TYPE_MILITARY_DISPLAY: 'Conscript',
  PASSENGER_TYPE_MILITARY_NAME: 'Conscript',
  PASSENGER_TYPE_MILITARY_INFO:
    'A discounted fare is only valid for certain situations and conscripts to the Norwegian Armed Forces, and not for employees of the Armed Forces:',
  PASSENGER_TYPE_MILITARY_RESTRICTIONS: [
    'Reise til og fra sesjon',
    'Oppmøte til-, og dimisjon fra, førstegangstjeneste når du reiser i sivilt',
    'Soldater i førstegangstjeneste',
    'Oppmøte til befalsopptak',
    'Militære lærlinger i 1. og 2. utdanningsår',
    'Befalselever i sitt 1. utdanningsår',
    'Oppmøte til nytilføring i Heimevernet eller oppmøte til repetisjonstjeneste',
    'Vernepliktig akademiker den dagen de reiser til pliktig oppmøte',
  ],
  PASSENGER_TYPE_MILITARY_DOCUMENTATION: `Du må kunne dokumentere at du oppfyller kravene ved å fremvise innkallingsbrev (mil. Bl. 102) eller Forsvarets blå ID-kort.`,
  PASSENGER_TYPE_STUDENT_DISPLAY: 'Student',
  PASSENGER_TYPE_STUDENT_NAME: 'Student',
  PASSENGER_TYPE_STUDENT_INFO: `You can purchase a discounted student fare if you are a student aged 18-29 years inclusive. Remember to show a student ID or other documentation from your college/university together with ID. An ISIC-card is considered valid ID for international students.`,
  PASSENGER_TYPE_STUDENT_RESTRICTIONS: [],
  PASSENGER_TYPE_CHILD_DISPLAY: 'Children 6-17 years',
  PASSENGER_TYPE_CHILD_NAME: 'Children 6-17 years',
  PASSENGER_TYPE_CHILD_INFO: `Children 6-17 fare applies to children aged 6-17 years on the day of departure. Children in this category can travel by themselves, but we recommend children travel with a companion until they are 12 years or over. Remember that parents or carers are always responsible for their child, even if they are not present on the train.`,
  PASSENGER_TYPE_CHILD_RESTRICTIONS: [],
  PASSENGER_TYPE_BICYCLE_DISPLAY: 'Bicycle',
  PASSENGER_TYPE_BICYCLE_NAME: 'Bicycle',
  PASSENGER_TYPE_BICYCLE_DESC: 'Special luggage',
  PASSENGER_TYPE_BICYCLE_INFO: `You can prebook a ticket for a bicycle on Sørtoget Region. On Jærtoget the ticket must be purchased on board the train. Wheelchairs and strollers/buggies have priority over bicycles. Please note that there is limited space, and wheelchairs and strollers/buggies are prioritised. This category can also be used for special luggage within the restrictions that apply. `,
  PASSENGER_TYPE_BICYCLE_RESTRICTIONS: [],
  PASSENGER_TYPE_SMALL_ANIMAL_DISPLAY: 'Small pet',
  PASSENGER_TYPE_SMALL_ANIMAL_NAME: 'Small pet',
  PASSENGER_TYPE_SMALL_ANIMAL_DESC: 'Under 40 cm',
  PASSENGER_TYPE_SMALL_ANIMAL_INFO: `The following pets can be brought on the train: dogs and cats that are under 40cm in height, caged rodents, birds and small turtles. Fish can be brought if the aquarium is packaged appropriately. Other animals cannot be brought. The pets must remain on the floor by the assigned seats and cannot sit in the seat or aisle. Animals cannot be brought into the café, the Extra carriage or sleeping cabins.`,
  PASSENGER_TYPE_SMALL_ANIMAL_ARTICLE: 'one',
  PASSENGER_TYPE_SMALL_ANIMAL_RESTRICTIONS: [],
  PASSENGER_TYPE_PUSH_CHAIR_DISPLAY: 'Stroller/buggy',
  PASSENGER_TYPE_PUSH_CHAIR_NAME: 'Stroller/buggy',
  PASSENGER_TYPE_PUSH_CHAIR_INFO: ` You can prebook a ticket for a buggy or stroller on Sørtoget Region. On Jærtoget and Sørtoget Local the ticket must be purchased on board the train. Please note that there is limited space, and wheelchairs are prioritized.`,
  PASSENGER_TYPE_PUSH_CHAIR_ARTICLE: 'one',
  PASSENGER_TYPE_PUSH_CHAIR_RESTRICTIONS: [],
  PASSENGER_TYPE_ANIMAL_DISPLAY: 'Large dog',
  PASSENGER_TYPE_ANIMAL_NAME: 'Large dog',
  PASSENGER_TYPE_ANIMAL_DESC: 'Over 40 cm',
  PASSENGER_TYPE_ANIMAL_INFO: `Dogs with a shoulder height of more than 40cm are given a separate seat to sit in front of. Dogs must remain on the floor by their assigned seat and cannot sit in the seat or aisle. Animals cannot be brought into the café, the Extra carriage or sleeping cabins.`,
  PASSENGER_TYPE_ANIMAL_ARTICLE: 'one',
  PASSENGER_TYPE_ANIMAL_RESTRICTIONS: [],
  PASSENGER_TYPE_SERVICEDOG_DISPLAY: 'Is it a working dog?',
  PASSENGER_TYPE_SERVICEDOG_NAME: 'Working dog',
  PASSENGER_TYPE_SERVICEDOG_INFO: `Working dogs such as service and rescue dogs travel free and can be brought into all parts of the train, with the exception of the animal free carriage. All service dogs must be equipped with a vest or harness with a lead/handle while rescue dogs should wear a triangle mark indicating they are working dogs. Valid documentation must be shown upon request. `,
  PASSENGER_TYPE_SERVICEDOG_RESTRICTIONS: [],
  PASSENGER_TYPE_1OR2TRAVELLERS_DISPLAY: '1 or 2 travellers',
  TO_MANY_PASSENGERS_ERROR_FIRST: `For group tickets, that is, 10 passengers or more, please contact our customer service through the`,
  MINIMUM_ONE_PASSENGER_ERROR: 'You must choose at least one passenger who is not a children under 6 years of age.',
  TO_MANY_PASSENGERS_ERROR_SECOND: ` schema here.`,
  INFANT_ONLY_PASSENGER_ERROR: `Children cannot travel unaccompanied.`,
  INFANT_TRAVELLING_WITH_CHILD_WARNING: `Please note that young children cannot travel with children under 13 years of age as their only companion.`,
  CHILD_TRAVELLING_ALONE_WARNING: `While children in this category may travel alone, we recommend that the child be over 12 years of age before doing so.`,
  INFANT_SUPPLEMENT_ERROR: 'Add-ons cannot be selected for a child 0-5 years',
  MAX_SUPPLEMENT_ERROR: 'You may only add one add-on per passenger. Bicycles are in addition to this.',
  MAX_PASSENGER_BICYCLE_ERROR: 'You may only add one bicycle per passenger.',
  MAX_BICYCLE_ERROR: 'The maximum number of bicycles per train is 5',
  CHOOSE_DEPARTURE: 'Select departure',
  SELECT_RETURN_TICKET: 'Select return',
  REVIEW_TICKET: 'Check your ticket',
  OK: 'OK',
  CONTINUE: 'Continue',
  PAY: 'Pay',
  AMOUNT_PAYED: 'Purchase price',
  PAYMENT: 'Payment',
  TRACK: 'Track',
  JOURNEY_TIME: 'Journey time',
  JOURNEY: 'Journey',
  JOURNEYS: 'Journeys',
  JOURNEY_DEPARTURE: 'Departure',
  JOURNEY_RETURN: 'Return',
  PASSENGERS: 'Passengers',
  SUPPLEMENTS: 'Add-ons',
  TRAVEL_DETAILS: 'Travel details',
  TICKETS: 'Tickets',
  PRICE_FROM: 'from kr.',
  PRICE: 'Fare',
  PRICE_STARTING_FROM: 'Fare from kr.',
  TAX_OF: 'VAT of',
  CHANGES: 'changes',
  CHANGE_SEARCH: 'Change search',
  CHANGE_DEPARTURE: 'Change departure',
  CHANGE_RETURN: 'Change return',
  CANCEL: 'Cancel',
  NO_DASH_START_OR_END: 'The dash must be between two names.',
  TICKET_RECIVE_METHOD_TEXT: 'How would you like to receive your tickets?',
  CONTACT_INFO: 'Contact information',
  EMAIL_ADRESS: 'Email',
  PHONENUMBER: 'Phone number',
  SHOW_MORE_JOURNEYS: 'Show more journeys',
  NO_MORE_JOURNEYS: 'No more journeys found',
  NO_TICKETS_FOUND: 'We found no tickets linked to your profile',
  NO_TICKETS_FOUND_FOR_USER:
    'Have you purchased a ticket that should be shown here? Please try again later or contact customer service who may be able to help-',
  MUST_BE_ATLEAST_TWO: 'The name must consist of at least two letters',
  BENEFIT_PROGRAM_TEXT_UPPER: `By creating a profile with us, you won't have to add in your details each time you purchase a ticket. We will save these for you. If you wish to learn more about how we store your personal data, please read our `,
  BENEFIT_PROGRAM_TEXT_LOWER:
    'You may create a profile providing you are 16 years or older. People under 16 years of age can create a profile with the consent of a parent/guardian. Please email customer service with the consent:',
  CONTACT_INFO_WARNING:
    'The main passenger is responsible for the tickets during the journey. Name and contact information is only used to issue tickets and for travel information for the booked journeys.',
  BUS_STATION_STOP: 'Stop',
  PAYMENT_METHOD_TEXT: 'Please choose payment method',
  ACCEPT_TERMS_AND_CONDITION_TEXT: `I have read and accepted Go-Ahead's`,
  TERMS: 'Terms',
  TRANSPORT_CONDITIONS: 'Conditions',
  PERSONAL_INTEGRITY_DECLARATION: 'Privacy statement',
  PURCHASE_FAILED: 'Unfortunately we were unable to complete your payment',
  PURCHASE_VIPPS_FAILED: 'Your payment with VIPPS was not completed. Please try again or contact customer service.',
  PURCHASE_FAILED_DESCRIPTION: `We were unable to complete the purchase. If you haven't cancelled the payment yourself, please contact customer service, and they will help you with the purchase of your tickets.`,
  SEND_PDF_FAILED:
    'Your purchase is complete, but we were unable to send all the travel documents to you. Please check that your email address is correct: ${email}. You can try to resend the documents using the button below, or contact customer service for help.',
  SEND_SMS_FAILED:
    'We have received your payment and completed the purchase, but the SMS has not sent as it should. Please contact customer service with your reference number, and they will be able to assist you further. ',
  OPENING_TIMES: 'Opening hours',
  MONDAY_TO_FRIDAY_OPENING_TIME: 'Monday-Friday: 07:00-23:00.',
  SATURDAY_OPENING_TIME: 'Saturday: 08:00-21:00.',
  SUNDAY_OPENING_TIME: 'Sunday: 09:00-23:00.',
  AND: 'and',
  TICKET: 'Ticket',
  PAYMENT_COMPLETE_TEXT: 'We have received the payment',
  PAYMENT_COMPLETE_BUT_CANT_SEND_TEXT: 'The purchase is complete, but the tickets have not been sent',
  PAYMENT_COMPLETE_BUT_CANT_SEND_SMS: 'The purchase is complete, but the SMS was not sent',
  COMPLETE_PDF_TEXT1:
    'The payment has been received, and you will shortly receive an email with your tickets and receipt.',
  COMPLETE_PDF_TEXT2: 'Please contact customer service if you do not receive the email within a reasonable time.',
  COMPLETE_ON_STATION_TEXT1:
    'The payment has been received, and you will be able to collect your ticket on the train, the ticket machine or at manned customer service counters by using your collection code:',
  COMPLETE_ON_STATION_TEXT2:
    'You will shortly receive an email with your collection code and receipt. Please contact customer service if you do not receive the email within a reasonable time.',
  HIDE_DETAILS: 'See details',
  SHOW_DETAILS: 'Hide details',
  TRAVEL_WHERE: 'Where would you like to go?',
  FROM: 'From',
  TO: 'To',
  FETCHING_YOUR_POSITION: 'Fetching your position',
  SWAP_STATIONS: 'Swap stations',
  NO_HIT: 'No results',
  SEE_ALL_STATIONS: 'See all stations',
  DELETE: 'Remove',
  EDIT: 'Change',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  CREATE_PROFILE: 'Create profile',
  NEW_USER: 'New user',
  NORWAY: 'Norway',
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARS: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  ALL: 'All',
  FUTURE: 'Future',
  PAST: 'Completed',
  CREATED: 'Created',
  DATE_OF_PURCHASE: 'Date of purchase',
  DATE_OF_TRAVEL: 'Date of travel',
  CALCULATED_BONUS: 'Calculated bonus',
  VERIFY_ACCOUNT_CUSTOMER_INFO: 'Verify your account on customer information',
  GIVE_PHONENUMBER: 'Enter phone number',
  GIVE_YOUR_PHONENUMER: 'Enter your phone number',
  PHONE_NUMBER_MODAL_TEXT:
    'You must enter your phone number in order to receive your ticket code via SMS. We will not store your phone number in your profile.',
  LOYALTY_PROGRAM_LABEL: 'I wish to join the loyalty program',
  LOYALTY_PROGRAM_HEADER: `Join Go-Ahead's loyalty program`,
  LOYALTY_PROGRAM_DESCRIPTION: `You can create a profile and join Go-Ahead's loyalty program. As a member who has purchased tickets via our website, we will ensure that you are automatically compensated if we are unable to keep to our travel guarantee. You will not have to do anything – the money will be returned to you automatically!`,
  LOYALTY_PROGRAM_HEADER_LOGGED_IN: `Membership in Go-Ahead's loyalty program`,
  LOYALTY_PROGRAM_DESCRIPTION_LOGGED_IN:
    'With our loyalty program we will ensure you receive any compensation you are entitled to without having to lift a finger. The money will automatically be returned to the card you used to pay for your tickets.',
  REFUND_LINK: 'Read more about compensation due to delays',
  JOURNEY_NOT_REFUNDABLE: 'This journey is non-refundable.',
  CUSTOMER_SERVICE: 'Customer service',
  SHOW_ADDONS: 'Show add-ons',
  HIDE_ADDONS: 'Hide add-ons',
  PHONE: 'Phone number',
  GO_ALIVE_WEB: 'Go-Alive (Web)',
  SEND_RECEIPT_EMAIL: 'Send receipt via e-mail',
  RECEIPT_SENT: 'The receipt has been sent',
  EMAIL: 'E-mail',
  CANT_BE_EMPTY: 'Cannot be left blank',
  PHONE_NUMBER_RESTRICTIONS: 'Only numbers are permitted',
  COUNTRY_CODE_ERROR: 'Must be between 1 and 4 numbers',
  MUST_BE_BETWEEN: 'Must be between',
  ONLY_LETTERS_ERROR: 'Can only include letters',
  NOT_VALID_BIRTHDAY: 'Invalid date of birth',
  NUMBERS: 'numbers',
  MUST_BE_FOUR: 'Must be 4 digits',
  NOT_VALID_DAY: 'Invalid day',
  MAX_CHAR_ERROR_80: 'Max 80 letters',
  MAX_CHAR_ERROR_50: 'Max 50 letters',
  MAX_NUMBERS_ERROR_2: 'Max 2 numbers',
  ONLY_NUMBERS_ERROR: 'Only numbers',
  NORWEGIAN: 'Norwegian',
  NORWEGIAN_PHONE: 'Phone number (norwegian)',
  ONLY: 'Only',
  GENERAL_ERROR_HEADLINE: 'Something went wrong!',
  GENERAL_ERROR_TEXT:
    'We are currently having technical problems. Please try again or contact customer service to complete your booking.',
  FETCH_JOURNEYS_ERROR:
    'We are currently having technical problems. Please try again or contact customer service to complete your booking. Ph: +47 61 25 80 00',
  NOT_AVAILABLE: 'Not available',
  SOLD_OUT: 'Sold out',
  JOURNEY_IS_CANCELLED: 'The journey has been cancelled',
  TRAIN_HAS_DEPARTED: 'This train has departed',
  TRAIN_LEFT_AT_PAST: 'Departed from',
  TRAIN_LEFT_AT_FUTURE: 'Departing from',
  NOT_AVAILABLE_CHOICE_ERROR: 'Not available for your selection',
  TICKET_PDF_TEXT: 'Print or save as PDF',
  TICKET_ON_TRAIN_TEXT: 'On the train',
  TICKET_ON_STATION_TEXT: 'Collect ticket',
  TICKET_PDF_SUBTEXT: 'Print ticket, or download to your mobile, tablet or laptop that you are bringing on the train.',
  TICKET_ON_TRAIN_SUBTEXT:
    'You will be sent a reference number to collect your ticket on the train. Find your seat on the train and the conductor will bring your ticket during ticket control. This is only valid on Sørtoget.',
  TICKET_ON_STATION_SUBTEXT:
    'You will be sent a collection code that you can use to collect your ticket at a ticket machine or at staffed stations. Please note not all stations have ticket machines.',
  DELAY: 'Delay',
  DELAY_PAST_TENSE: 'delayed',
  MUST_HAVE_NORWEGIAN_PHONE_SHORT: 'Must be a valid Norwegian phone number',
  MUST_HAVE_PHONE_VIPPS: 'Only available with a Norwegian phone number',
  NORWEGIAN_NUMBER_REQIRED: 'Norwegian phone number (obligatory)',
  ADD_PHONE_NUMBER: 'Add phone number',
  MAX_ERROR: 'cannot be more than',
  CHARACTERS: 'characters',
  DAYS: 'days',
  SELECTABLE_DAYS_INFO: 'We add routes approximately 90 days in advance. Later dates are therefore not available.',
  HENTE_CODE: 'Collection code',
  REFERENCE_NUMBER: 'Reference number',
  REFERENCE: 'Reference',
  TRANSFER_TIME: 'Transfer time',
  TRANSFER_GET_ERROR_HEADLINE: 'We cannot find any departures',
  TRANSFER_GET_ERROR_TEXT:
    'Something went wrong when we tried to find alternative departures, please try again later or contact customer service.',
  TRANSFER_REPLACE_ERROR_HEADLINE: 'We are unable to change your departure',
  TRANSFER_REPLACE_ERROR_TEXT:
    'Due to an unknown error we were unfortunately no able to change the departure for this journey, please try again later or contact customer service.',
  EMAIL_ADRESS_VALIDATION_TEXT: 'You must add a valid e-mail',
  NO_JOURNEYS_FOUND: 'No departures found',
  ALTERNATIVE_JOURNEYS: 'Alternative departures',
  JOURNEY_LEAVE: 'Departure',
  TICKET_NOT_AVAILABLE: 'Unfortunately, this ticket is no longer available',
  PAYMENT_METHODS_AND_PAYMENT: 'Complete purchase',
  ALTERNATIV_TRANSPORT: 'Alternative transport',
  RETURN_JOURNEY_WARNING: 'This journey starts before you have arrived at',
  ALTERNATIVE_PAYMENT_OPTIONS: 'Show other payment methods',
  SEAT: 'Seat',
  NO_SEAT_RESERVATION: 'No seat reservation',
  WAGON: 'Carriage',
  BACK_TO_MAIN_PAGE: 'Back to homepage',
  BACK: 'Back',
  CREATE_ACCOUNT: 'Create profile',
  SHOW_PRICE_DETAILS: 'Show price details',
  HIDE_PRICE_DETAILS: 'Hide price details',
  REMEMBER_ME: 'Remember me',
  PASSWORD: 'Password',
  NO_ACCOUNT_YET: 'Have you not created a profile yet?',
  CREATE_PROFIL: 'Create profile',
  SHOW_PASSWORD: 'Show password',
  HIDE_PASSWORD: 'Hide password',
  FORGOT_PASSWORD: 'Forgotten password?',
  LOG_IN: 'Log in',
  MY_PAGES: 'My pages',
  CREATE_PROFILE_SUCCESS_HEADING: 'Your profile has been created',
  CREATE_PROFILE_SUCCESS_TEXT: 'Thank you for creating a profile with us. We wish you a pleasant journey!',
  COMPLETE_SEARCH_ABOVE: 'Please complete search above',
  NON_SELECTED: 'Not selected',
  INVAILD_DEPATURE_STATION: 'Invalid departure station.',
  VALID: 'Valid',
  CURRENT_PASSWORD: 'Current password',
  NEW_PASSWORD: 'New password',
  YOUR_NEW_PASSWORD: 'Your new password',
  REPEAT_NEW_PASSWORD: 'Repeat new password',
  REPEAT_PASSWORD: 'Repeat password',
  EDIT_PROFILE_SUCCESS_PAGE_MSG: 'Your changes have been saved.',
  CREATE_PROFILE_INFO:
    'You may create a profile providing you are 16 years or older. Those under 16 years of age may create a profile with the written consent of their parent/guardian. Please email customer service with the consent on: ',
  CREATE_PROFILE_ERROR_HEADING: 'Your profile was not created',
  CREATE_PROFILE_ERROR_TEXT:
    'Something has gone wrong, and we were unable to create your profile. It may be because there is already a profile connected to this email address. Please try to log in or create the profile again by using the options below.',
  CREATE_PROFILE_SMS_INFO:
    'Our SMS-solution is unfortunately not quite ready yet, but select this if you would like us to contact you via SMS in the future.',
  BIRTHDATE: 'Date of birth',
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',
  COUNTRY: 'Country',
  COUNTRY_CODE: 'Country code',
  POSTCODE: 'Postcode',
  THROUGH_EMAIL: 'Via e-mail',
  THROUGH_SMS: 'Via text message',
  PAYMENT_CARD: 'Payment card',
  ARRIVED: 'Arrived',
  CLOCK: 'o’clock',
  CLOCK_SHORT: `o'clock`,
  PAYMENT_CARD_INFO1:
    'We recommend that you save your payment details so that you do not have to add these each time you purchase tickets. The information will be safely stored by Nets.',
  PAYMENT_CARD_INFO2:
    'You can choose to save your payment details directly in the app, but please be aware that the information will then be deleted if you uninstall the app or change phones. If you save the details to My pages online, they will be stored for use both online and in the app.',
  CARD_STORED_AT: 'Payment details stored with our payment provider',
  CARDS_EMPTY_MESSAGE: 'You have not added any payment details. Click on Add card to add a payment option.',
  ADD_CARD: 'Add card',
  DELETE_CARD: 'Remove card',
  SAVE_CARD: 'Save card',
  NICKNAME: 'Nickname',
  CARD_NICKNAME_OPTIONAL: 'Nickname (optional)',
  SET_CARD_PRIMARY: 'Save as main card',
  PRIMARY: 'Main card',
  CHANGE_OTHER_CARD_MSG: 'You must choose another card as the main card before removing this card',
  CARD_DELETE_MESSAGE: 'Are you sure you want to remove this card?',
  CARD_FETCH_ERROR_HEADLINE: 'Cannot retrieve card details',
  CARD_FETCH_ERROR_TEXT:
    'Unfortunately we were unable to retrieve your card information, please try again or contact customer service.',
  CARD_ADD_ERROR_HEADLINE: 'Cannot add card details',
  CARD_ADD_ERROR_TEXT: 'We were unable to add your card details. Please try again or contact customer service.',
  CARD_ADD_SUCCESS_TEXT: 'We have added your card details',
  CARD_UPDATE_ERROR_HEADLINE: 'Cannot update card details',
  CARD_UPDATE_ERROR_TEXT:
    'Unfortunately we were unable to update your card details. Please try again or contact customer service.',
  CARD_UPDATE_SUCCESS_TEXT: 'We have updated your card details',
  CARD_DELETE_ERROR_HEADLINE: 'Cannot remove card details',
  CARD_DELETE_ERROR_TEXT:
    'Unfortunately we were unable to remove your card details. Please try again or contact customer service.',
  CARD_DELETE_SUCCESS_TEXT: 'We have removed your card details',
  INFO_ABOUT_JOURNEYS: 'Information about my journeys, tickets, and payment methods',
  INFO_ABOUT_MY_JOURNEYS: 'Information about my journeys, tickets...',
  MY_JOURNEYS: 'My journeys',
  MY_JOURNEYS_PARAGRAPH:
    'The tickets in this overview are not valid travel documents. If you need help printing out a new ticket or have purchased a ticket that should show here, please contact customer service who will be able to help.',
  MY_JOURNEYS_NO_TICKETS_TITLE: 'This is an overview of your purchases',
  MY_JOURNEYS_NO_TICKETS_PARAGRAPH:
    'This is an overview of your purchases. For now, we cannot find any tickets linked to your profile.',
  INFO_ABOUT_OFFERS: 'Good offers and tips tailored for me',
  ON_STATION_TICKETS: 'Collection code for tickets',
  ACCEPT_CREATE_PROFILE: 'I accept and would like to create a profile',
  OPTIONAL_CHOICE: 'Optional',
  MANDATORY: 'This field must be filled out',
  MANDATORY_CHOICE: 'Obligatory, cannot be changed',
  MANDATORY_FIELD: 'Obligatory',
  MANDATORY_CHOOSE_OPTION: 'You must choose an option',
  MANDATORY_STATION: 'You must choose a station',
  CHOOSE_OPTION: 'Choose an option',
  COMMUNICATIONS_SETTINGS: 'Communication preferences',
  WHEN_CREATING_PROFILE: 'When you create a profile with us',
  RECEIPTS: 'Receipts',
  TICKET_BOUGHT_FROM: 'Ticket bought from',
  ACCEPT_PROFILE_CONDITIONS:
    'we would like to save the information you have given us. If you wish to read about how we process your personal data, please read our',
  CONTINUE_WITHOUT_ACCOUNT: 'Continue without account',
  CLIENT_INFORMATION: 'Customer information',
  CLIENT_DATA: 'My Data',
  HANDLING_CLIENT_DATA: 'Processing your personal data',
  LOGOUT: 'Log out',
  WRONG_CREDENTIALS: 'Wrong username or password',
  CHANGE_TO_NEW_PASSWORD: 'Change password',
  FORGOT_PASSWORD_INFORMATION:
    'Have you forgotten your password? Enter your e-mail and you will be sent instructions to change your password.',
  CHANGE_PASSWORD: 'Change password',
  FORGOT_PASSWORD_SUCCESS:
    'We have sent you an email with a new password. After logging in, you should change the password in your profile. Please note that if you do not have a profile registered to this e-mail address, we will not have sent you an email.',
  CUSTOMER_INFORMATION: 'Customer information',
  CUSTOMER_INFORMATION_SUM: 'Personal data, Communication settings...',
  PROFILE_DATA_HEADLINE: 'Viewing and deletion of data',
  PROFILE_DATA_PARAGRAPH:
    'You have the right to know what information we hold about you, and can request access to this. You can also request that we delete your profile and all personal data. If you wish to delete your profile with us, click on the Delete profile button at the bottom of the page. For assistance to access the information we hold about you, please contact customer service:',
  PROFILE_DATA_COMPLAIN:
    'If you are unhappy with how we have handled your personal data, it is the responsibility of The Norwegian Data Protection Authority to enforce the regulations and you can make a complaint to them..',
  DELETE_PROFILE: 'Delete profile',
  DELETE_MY_PROFILE: 'Delete my profile',
  PROFILE_DELETE_INFO_UPPER:
    'Your profile will normally be deleted within 3 days. At this point you will be automatically logged out of the website and app and will not be able to log back on without creating a new profile. If you have downloaded the app to your phone, you will need to remove this yourself.',
  PROFILE_DELETE_INFO_LOWER:
    'It will not be possible to delete some data as we are obligated to retain these for a certain amount of time. These will be deleted following standard routines and timelines.',
  PROFILE_DELETE_CONSENT_HEADLINE: 'I accept the deletion of data',
  PROFILE_DELETE_CONSENT_TEXT: 'I understand that by clicking Delete my profile I accept that:',
  PROFILE_DELETE_CONSENT_BOX_ONE:
    'I will no longer have access to my profile and the services associated with it. This applies to both the website and app.',
  PROFILE_DELETE_CONSENT_BOX_TWO:
    'I will lose access to all tickets that are currently active or that I have bought for a future journey.',
  PROFILE_DELETE_CONSENT_BOX_THREE:
    'Deleting my data is final and I cannot retrieve the information. If I want a profile again, I will have to make a new profile',
  PROFILE_DELETE_SUCCESS_TEXT:
    'You are automatically logged out from the website and all data connected to your profile will be deleted. This normally happens within 3 days. If you have a mobile app, you will automatically be logged out. However, you must manually delete the app from your phone.',
  PROFILE_DELETE_ERROR_TEXT:
    'It looks like something went wrong with the deletion of your profile. Please try again or contact customer service.',
  PROFILE_DELETE_ERROR_MESSAGE: 'You must accept the conditions before you can delete your profile',
  PERSONAL_INTEGRITY_BTN: 'Data protection at Go-Ahead',
  ADDRESS: 'Address',
  CHANGE_PASSWORD_ERROR_HEADING: 'We were unable to create a new password',
  CHANGE_PASSWORD_ERROR_TEXT:
    'Something has gone wrong and we have been unable to create a new password for you. Please try again.',
  SAVE_CHANGES_TECHNICAL_ERROR:
    'We have been unable to process the changes due to a technical error. Please try again.',
  CREATE_PROFILE_PASSWORD_ERROR:
    '5-100 characters, including numbers and a combination of uppercase and lowercase letters',
  CHANGE_PASSWORD_ERROR_BUTTON_TEXT: 'Change password',
  CHANGE_PASSWORD_SUCCESS_HEADING: 'The password has been changed',
  CHANGE_PASSWORD_INVALID_PASSWORD_TEXT: 'The password does not meet the criteria',
  CHANGE_PASSWORD_NEW_OLD_SAME: 'New and old passwords cannot be the same',
  CHANGE_PASSWORD_NOT_EQUAL: 'The passwords do not match',
  CHANGE_PASSWORD_INFO_HEADLINE: 'Remember security! Create a secure password by:',
  CHANGE_PASSWORD_INFO_LENGTH: 'using 5-100 character',
  CHANGE_PASSWORD_INFO_CHARACTERS: 'using a combination of lowercase and uppercase letters',
  CHANGE_PASSWORD_INFO_NUMBERS: 'use numbers',
  CHANGE_PASSWORD_INFO_GENERAL: 'not using words that are easily linked to you or the service',
  SAVE_CHANGES: 'Save changes',
  CHANGES_HAVE_BEEN_SAVED: 'The changes have been saved.',
  GENERAL_CHANGE_ERROR_HEADING: 'The changes have not been saved',
  GENERAL_CHANGE_ERROR_TEXT:
    'Something has gone wrong, and we have been unable to save your changes. Please try again later.',
  MENU: 'Menu',
  PROFILE: 'Profile',
  WALK_LESS_THAN_MINUTE: 'Walk for less than 1 min',
  WALK: 'Walk',
  SEARCH_AGAIN: 'Search again',
  SEARCH_FROM_START_HEADLINE: 'You have to search again',
  SEARCH_FROM_START_TEXT: 'You have to start your search again once you have created your profile.',
  NEXT_DAY: 'Next day',
  NO_JOURNEYS_FOUND_TEXT: `We couldn't find any journeys that matched your search. Would you like to try the next day?`,
  CANCEL_WARNING_HEADER: 'Warning!',
  CANCEL_PURCHASE_TEXT: 'If you choose to leave this page the information you have input will not be saved.',
  CANCEL_PURCHASE_BACK: 'Back to booking',
  CANCEL_PURCHASE_PROCEED: 'Cancel booking',
  CANCEL_FORM_TEXT:
    'The changes are not saved. If you choose to leave the page without saving, all information will be deleted and reset to what you had added earlier.',
  CANCEL_FORM_BACK: 'Go back to save',
  CANCEL_FORM_PROCEED: 'Proceed without saving',
  CANCEL_TICKET: 'Cancel ticket',
  CANCEL_THE_TICKET: 'Cancel the ticket',
  CANCEL_TICKET_ERROR_TEXT: 'with the cancellation of your ticket. Please try again or contact customer service',
  TICKET_IS_CANCELLED: 'The ticket has been cancelled',
  CANCELLED: 'Cancelled',
  TRY_AGAIN: 'Try again',
  THIS_IS_EMPTY: 'This is empty',
  CANCEL_TICKET_PARAGRAPH: `If you are unable to travel with the ticket you've purchased, you can cancel the ticket using the button below. If you have a Fleks ticket, you will be refunded the full amount up until 24 hours before departure. After this, you will be charged a fee of 100kr.`,
  END_MEMBERSHIP_TEXT: 'End subscription. I no longer want to take part in the loyalty programme',
  CHOOSE_SLEEP_COMPARTMENTS: 'Choose number of compartments',
  COMPARTMENT: 'Compartment',
  COMPARTMENTS: 'Compartments',
  CUSTOMER_SERVICE_WHEELCHAIR: 'Are you travelling with a wheelchair or stroller/buggy?',
  TRAINS_HAVE_WHEELCHAIR_SPACE:
    'If you wish to reserve floorspace for a wheelchair or stroller you can book this via customer service. Remember that you can travel with your stroller as luggage. In that case you do not need to book a space. When you arrive at your train, you will need to pack the stroller/buggy together and place it in the luggage area. ',
  PHONE_TO_CUSTOMER_SERVICE: 'Phone number for customer service:',
  CHANGE_SEAT: 'Change seat',
  CHOOSE_SEAT: 'Choose seat',
  SEAT_SELECTOR_ERROR_HEAD: 'We were unable to display the seat selection',
  SEAT_SELECTOR_ERROR_TEXT:
    'We were unable to display the seat selection. If you choose to continue, you will be assigned a seat automatically.',
  RESERVE_SEAT_ERROR_HEAD: 'We were unable to reserve a seat',
  RESERVE_SEAT_ERROR_TEXT:
    'We were unable to reserve your seat. If you choose to continue, you will be assigned a seat automatically.',
  SEAT_SELECTOR_EMPTY_ERROR_BODY:
    'Unfortunately something went wrong with the search for available seats, please try again later.',
  OF: 'of',
  SEATS_IS_CHOOSEN: 'seats are selected',
  TRAIN_DIRECTION: 'Train direction',
  DIRECTION: 'Direction',
  TOWARDS: 'towards',
  YOU_CAN_CHANGE_SEAT: 'You can change seats by selecting your chosen seat. Seat reservation is free.',
  CHOOSEN_SEAT: 'Chosen seat',
  AVAILABLE_SEAT: 'Available seat',
  AVAILABLE_SEATS: 'available seats',
  NOT_AVAILABLE_SEAT: 'Not available',
  POSSIBLE_WRONG_DIRECTION:
    'The seat or seats you have chosen may not face the direction of travel for some or all of the journey.',
  WHEEL_CHAIR_SPACE: 'Wheelchair space',
  BABY_CARRIAGE_SPACE: 'Stroller/buggy space',
  NURSING_SPACE: 'Changing table',
  PLAYROOM: 'Playroom',
  WC: 'Toilet',
  CHANGE_RAILCAR: 'Change carriage',
  CLICK_TO_CHANGE_RAILCAR: 'Click to choose another carriage.',
  RAIL_CAR: 'Carriage',
  CATEGORY: 'Category',
  DEVIATIONS: 'service disruptions',
  SHOW_TRAVELLING_DETAILS: 'Show travel details',
  ONLY_ONE_CARRIAGE_MESSAGE: 'For your chosen ticket, seats are only available in this carriage.',
  READ_MORE: 'Read more',
  RESERVATION_EXPIRED_HEADLINE: 'Reservation expired.',
  RESERVATION_EXPIRED_TEXT: 'If you still wish to book tickets you will need to start the search again.',
  CHANGE_DEPARTURE_MESSAGE: 'We have changed the departure time for your journey.',
  CHANGE_RETURN_MESSAGE: 'We have changed the departure time for your return journey.',
  CHANGE_TRANSFER_MESSAGE:
    'Because you have changed the transition period, some changes may have been made. Please read through your order again to check that it is correct.',
  PAYMENT_SERVICE_ERROR:
    'Unfortunately something went wrong with the payment. Please try again later or contact customer service to complete your booking. Ph: +47 61 25 80 00',
  SMS_CONSENT_TEXT: 'I consent to receiving the ticket code via text message. ',
  YOUR_CONSENT_NEEDED: 'Your consent is needed',
  YOU_MUST_CONSENT_SMS:
    'You must consent to receive the ticket code via text message. Your choice will be stored to your communication settings, and you may change these later should you wish.',
  FAILED_TO_SAVE_CONSENT: 'We were unable to save your consent.',
  SMS_INFO: 'You must create an account to receive a ticket code via text message',
  GO_TO_PROFILE: 'go to profile',
  MODAL_GO_TO_PROFILE_HEADLINE: 'You can change preferences in your profile',
  MODAL_GO_TO_PROFILE_TEXT:
    'You can change this under communication preferences in your profile. Your search will be cancelled when you go to your profile.',
  TICKET_DETAILS_ERROR_TEXT:
    'We were unable to display your ticket details. Please try again later or contact customer service',
  REBOOK_SLEEPER: 'Contact customer service if you wish to change your journey',
  REBOOK_SLEEPER_COMPARTMENT: 'Rebook sleeper compartment',
  APP_AD_TEXT: 'Did you know we have an app? You will find it ',
  HERE_LINK: 'here',
  OPEN_OVERVIEW: 'Open overview',
  CLOSE_OVERVIEW: 'Close overview',
  PAGE_TITLE_PLANNER: 'Journey planner',
  PAGE_TITLE_DEPARTURE: 'Select departure',
  PAGE_TITLE_RETURN: 'Select return',
  PAGE_TITLE_LOGIN: 'Log in or create a profile',
  PAGE_TITLE_REVIEW: 'Check your ticket',
  PAGE_TITLE_PAYMENT: 'Add payment information',
  PAGE_TITLE_COMPLETE: 'Payment complete',
  PAGE_TITLE_PROFILE: 'My profile',
  PAGE_TITLE_CREATE_PROFILE: 'Create profile',
  PAGE_TITLE_EDIT_PROFILE: 'Change my profile',
  PAGE_TITLE_CHANGE_PASSWORD: 'Change password',
  PAGE_TITLE_MY_DATA: 'Privacy and processing of personal data',
  PAGE_TITLE_DELETE_PROFILE: 'Delete my profile',
  PAGE_TITLE_MY_JOURNEYS: 'My journeys',
  PAGE_TITLE_MY_CARDS: 'My payment details',
  PAGE_TITLE_DEFAULT: 'Go-Ahead',
  PAGE_TITLE_NOT_FOUND: 'Page not found',
  LABEL_NAVIGATE_TO_START_PAGE: 'Navigate to start',
  LABEL_ADD: 'Add',
  LABEL_SUBTRACT: 'Subtract',
  LABEL_LOGIN_WITH_EXSISTING: 'Log in with existing account',
  LABEL_CREATE_NEW_PROFILE_INFO: 'Information about creating a new account',
  LABEL_JOURNEY_INFORMATION: 'Journey information',
  LABEL_SEARCH_RESULT: 'Search result',
  LABEL_HOW_TO_RECEIVE_TICKET: 'Choose how to receive your ticket',
  LABEL_CHOOSE_PAYMENT_METHOD: 'Choose how you wish to pay',
  LABEL_ACCEPT_GOAHEAD_TERMS: `Accept Go-Ahead's terms and conditions`,
  LABEL_CHOOSE_DESTINATIONS: 'Choose destination',
  LABEL_SEARCH_DESTINATIONS: 'Search destinations',
  SEARCH_JOURNEY_BTN: 'Find journey',
  LABEL_CHOOSE_DATE_AND_TIME: 'Choose time and date',
  LABEL_CHOOSE_PASSENGERS_AND_SUPPLEMENTS: 'Choose passengers and add-ons',
  LABEL_REVIEW_TICKETS: 'Review your tickets',
  LABEL_PASSWORD_INFO: 'Information about password restrictions',
  LABEL_CHANGE_PASSWORD: 'Change your password',
  LABEL_DELETE_ACCOUNT_INFO: 'Information about deleting your account',
  LABEL_PROFILE_INFO: 'Profile information',
  LABEL_ENTER_PROFILE_INFO: 'Enter profile information',
  LABEL_GOAHEAD_CONTACT_INFO: 'Go-Ahead contact information',
  LABEL_PROFILE: 'Profile',
  LABEL_CHOOSE_PROFILE_SECTION: 'choose profile section',
  LABEL_INFO_ABOUT_STORING_CARDS: 'Information about storing card details',
  LABEL_LIST_OF_STORED_CARDS: 'List of stored cards',
  LABEL_COMMS_SETTINGS: 'Communication preferences',
  LABEL_CHOOSE_TICKET_CATEGORY: 'Choose ticket category',
  LABEL_CLOSE_MODAL: 'Close window',
  LABEL_TICKET_SMS: 'Send ticket code via text message',
  ERROR_ACCEPT_TERMS: 'You must accept the travel terms and conditions and privacy statement to proceed.',
  CHAT: 'Chat',
  ONE_SLEEP_COMPARTMENT: '1 sleep compartment',
  SHARED_SLEEP_COMPARTMENT: 'Shared sleep compartment',
  OPEN_CHAT: 'Open chat',
  NO_DEVIATIONS: 'There are no service disruptions at the moment',
  ON_GOING_WORK: 'Ongoing work',
  PLANNED_WORK: 'Planned maintenance',
  ROUTE_HOME: 'Home',
  ROUTE_DEVIATIONS: 'Traffic information',
  ROUTE_ARTICLES: 'Articles',
  CMS_ERROR_HEADLINE: 'Something went wrong',
  CMS_ERROR_LOADING_MAIN_TEXT:
    'Oops! We are having some technical difficulties at the moment. But you should still be able to search for journeys.',
  OPEN_MENU: 'Open menu',
  GO_BACK: 'Go back',
  SEARCH_COMPONENT_NO_SAME: `Stations for departure and arrival can't be the same.`,
  NO_STATIONS_SYS_ERR_TEXT: 'It looks like we are unable to contact our database.',
  GET_MY_POSITION: 'Get my position',
  FIND_JOURNEY_ENTUR: 'Find journey with Entur',
  LOADING: 'Loading',
  PAGE: 'Page',
  LIST: 'List',
  VALID_FROM: 'Valid from',
  DELETE_STATION: 'Delete station',
  STATIONS: 'Stations',
  STATION: 'Station',
  SHORTCUTS: 'Shortcuts',
  EARLIER_SEARCH: 'Earlier search',
  BUY_SORPASSET: 'Buy Sørpasset',
  ORDER_ASSISTANCE_REQ: 'Please wait, ordering assistance',
  YES: 'Yes',
  NO: 'No',
  DESCRIPTION_OF_NEED: 'Description of need',
  CONTACT_CUSTOMER_SERVICE: 'Please contact customer service',
  BOOK_ASSISTANCE: 'Book assistance',
  SEND_ORDER: 'Send order',
  ASSISTANCE_LOCATION: 'Where do you need assistance?',
  ASSISTANCE_NEEDS: 'Assistance needs',
  ASSISTANCE_AID: 'Aids that you use',
  TRAVELLING_ALONE: 'Are you travelling alone?',
  TOTAL_LUGGAGE: 'Amount of baggage (max 3 pieces)',
  CONFIRMATION_CHOICE: 'Would you like confirmation on:',
  ASSISTANCE_DEPARTURE_INFO: 'Departure: Where and when would you like to be met?',
  ASSISTANCE_DESTINATION_INFO: 'Destination: Where would you like to be escorted to?',
  ASSISTANCE_ORDER_SUCCESS_HEADLINE: 'Thank you for your booking!',
  ASSISTANCE_ORDER_SUCCESS_TEXT: 'Bane Nor will contact you for further information. We wish you a pleasant journey!',
  ASSISTANCE_ORDER_CASE_NUMBER: 'Your case number is: ',
  CASE_NUMBER: 'Case number',
  CONTACT_BANENOR: 'Please contact Bane NORs customer service if you have any questions.',
  ASSISTANCE_ORDER_ERROR_TITLE: 'Your booking for assistance failed',
  ASSISTANCE_IS_BOOKED: 'Assistance has been booked.',
  LEGAL_LIABILITY_ASSISTANCE_1:
    'Bane NOR is the data controller for personal data collected to offer assistance. Go-Ahead is the data processor, and collect the information on behalf of Bane NOR.',
  LEGAL_LIABILITY_ASSISTANCE_2:
    'The purpose of Bane NORs processing of personal data is to be able to offer assistance to travellers with a need for this. To deliver this, Bane NOR requires information about the type of need, time, place, and contact information. Bane NOR are required to share this information with those who will provide the assistance, for example the train operator, security, or a taxi service. Bane NOR processes the data in order to carry out a task in the public interest or because it is necessary due to important public interests when special categories of personal data, such as health data, are processed. Bane NOR is required to offer assistance services according to the railway passenger rights regulations.',
  LEGAL_LIABILITY_ASSISTANCE_3: `We ask that you do not register more information than is necessary to describe your need for assistance, and that you avoid registering health information as far as possible. You can find more information, including your rights, appeal options and contact information for Bane NOR's data protection representative, in Bane NOR's`,
  ASSISTANCE_ORDER_ERROR_TEXT: `Booking of assistance failed. Please check your internet connection and try again. If it still fails, please contact Go-Ahead's customer service.`,
  ASSISTANCE_LOAD_FORM_ERROR: `Unfortunately we were unable to load your booking of assistance now. Please check your internet connection and try again. If it still fails, please contact Go-Ahead's customer service.`,
  ASSISTANCE_TOO_LATE:
    'Unfortunately it is not possible to book assistance online for your journey. This must be done within 24 hours of departure. However, we will be happy to help you book assistance if you contact customer service.',
  SORPASSET_SALE_IS_OVER: 'The sale of Sørpasset has ended for now.',
  SORPASSET_LOGIN_HEAD: 'You must log in to book Sørpasset',
  SORPASSET_LOGIN_TEXT:
    'You must log in to book Sørpasset. This is so that we can apply the discount when you book your seats later.',
  SR_OPEN_TRANSPORT_TAB: 'Open journey terms and conditions in a new tab',
  SR_OPEN_INTEGRITY_TAB: 'Open privacy statement in a new tab',
  SR_OPEN_IN_NEW_TAB: 'Open ${page} in a new tab',
  SR_PICK_STARTDATE: 'Choose start date',
  ORDER: 'Book',
  SEND: 'Send',
  SEND_AGAIN: 'Send again',
  SEND_TICKET_AGAIN: 'Send ticket again',
  MAIL_SUBJECT_MISSING_TICKET: 'Missing ticket for this order',
  ERROR_SEND_PDF_HEAD: 'Unfortunately we are unable to send the ticket to you.',
  SUCCESS_SEND_PDF_HEAD: 'The ticket has been sent',
  SUCCESS_SEND_PDF_TXT: 'We were able to send you the ticket now, please check your inbox or spam.',
  CUSTOMER_SERVICE_TXT1: 'Please contact customer service if the problem persists. ',
  CUSTOMER_SERVICE_TXT2: 'Please contact customer service and they will be able to help.',
  DOWNLOAD_APP: 'Download the Go-Ahead app',
  SKIP_TO_CONTENT: 'Skip to content',
  VIPPS_TIMEOUT_TITLE: ' The payment is taking longer than usual to process',
  VIPPS_TIMEOUT_TEXT: ' Your payment with Vipps is taking longer to process than usual.',
  VIPPS_TIMEOUT_PDF_TEXT: `Please come back in a couple of minutes to check if you have received your ticket and receipt.`,
  SEE_ALL_DEVIATIONS: 'View all status updates',
  REQUEST_DEVIATIONS_ERROR:
    'Unfortunately something went wrong when trying to retrieve status updates. Please try again later or contact customer service.',
  ONLY_VALID: 'Only valid',
  APPLIES_FOR: 'Applies for',
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  TODAY_FROM: 'Today from',
  TOMORROW_FROM: 'Tomorrow from',
  PURCHASE_CANCELLED: 'The purchase was cancelled',
  GO_BACK_AND_TRY_AGAIN: 'Please go back and try again.',
  BUS_FOR_TRAIN: 'Bus for train',
  CRITICAL_DEVIATIONS: 'Service disruptions',
  IS_TRAIN_ON_TIME: 'Status updates',
  IS_TRAIN_ON_TIME_PREAMBLE: 'Is the train on time?',
  TECHNICAL_ERROR_TEXT: 'We are currently experiencing technical difficulties. Please try again.',
  BETWEEN: 'between',
  HOURS_SHORT: 'h',
  MINUTES_SHORT: 'min',
  HANDLING_PERSONAL_INFO: 'How we handle personal information',
  ACCEPT_CONDITIONS_TEXT: 'I have read and accepted Go-Aheads Transport conditions and Personal integrity statement',
  ONE_WAY: `One way`,
  TURN_RETURN: `Turn/Return`,
  COOKIES_TITLE: 'Cookie settings',
  LANGUAGE: 'Language',
  SELECT_LANGUAGE: 'Select language',
  ZIPCODE: '0000',
  CUSTOMER_NUMBER: 'Customer number',
  NO_DEVIATIONS_HEADLINE: 'No deviations',
  NO_DEVIATIONS_TEXT: 'Nothing to show here. It looks like everything is running smoothly at the moment.',
  ADD_DISCOUNT_CODE: 'Enter discount code',
  SAVE: 'Save',
  HAVE_DISCOUNT_CODE: 'Do you have a discount code?',
  MY_DISCOUNT_CODES: 'My discounts',
  ADD_DISCOUNT_ERROR: 'Your code is either invalid or already added to your account.',
  YOUR_ORDER_STEP: 'Your order step ',
  UNTIL: 'until',
  PLEASE_WAIT: 'Please wait',
  ADD_DISCOUNT_SUCCESS: 'The discount code has been saved to your profile',
  NOT_FOUND_HEADLINE: 'Oops',
  NOT_FOUND_PREAMBLE: `It looks like the page has been moved or no longer exists. Maybe you can find what you're looking for here?`,
  COMPARTMENT_NOT_AVAILABLE: 'There are not enough sleeper cabins available to meet your request.',
  COMPARTMENT_ERROR: 'Due to a technical error, we are unable to change the number of sleeper cabins',
  ENTUR_MEMBER_ID: 'Entur Member ID (optinal)',
  REMEBER_TO_CHECK_ENTUR_ID: 'Remember to double check that your entur id is correct',
  STRAWBERRY_EMAIL: 'Strawberry E-mail',
  STRAWBERRY_PHONE: 'Strawberry Phonenumber (norwegian)',
  STRAWBERRY_ID: 'Strawberry ID',
  STRAWBERRY_UNVERIFIED: 'You are not verified',
  STRAWBERRY_VERIFIED: "You are verified and don't have to do anything else",
  STRAWBERRY_INFORMATION: 'Strawberry information',
  STRAWBERRY_BONUS: 'Strawberry bonus',
  STRAWBERRY_SLUG: 'strawberry-en',
  STRAWBERRY_VERIFICATION_ERROR:
    'We could not find anyone with that strawberry id. Please double check strawberry id and email (or phone)',
  VERIFY: 'Verify',
  STRAWBERRY_MAIL_OR_PHONE_ERROR: 'You must fill in either email or phone number. Not both',
  CHOOSE_STATION: 'Choose a station',
  FREIGHT_TRAIN: 'Freight train',
  OFFER_LOW_PRICE_TRANSLATION: 'Low fare',
  OFFER_LOW_PRICE_DESCRIPTION: 'No changeing or refund',
  OFFER_FLEKS_TRANSLATION: 'Flex',
  OFFER_FLEKS_DESCRIPTION: 'Is refundable',

  PRODUCT_STANDARD_DESCRIPTION: 'This is our standard seat, and cheapest alternative.',
  PRODUCT_EXTRA_TRANSLATION: 'Extra',
  PRODUCT_EXTRA_DESCRIPTION:
    "Extra is for those who wish for a little extra: fresh coffee, snackbar and a seat in the train's most quiet part.",
  PRODUCT_FAMILY_TRANSLATION: 'Family',
  PRODUCT_FAMILY_DESCRIPTION:
    'Family is for those who travel with kids. Here you will be placed in your own wagon with a playroom and changing table in the toilets.',
  PRODUCT_NOPETSALLOWED_TRANSLATION: 'Animal free',
  PRODUCT_NOPETSALLOWED_DESCRIPTION: 'In our animal free wagon no pets/animals are allowed, not even service dogs.',
  PRODUCT_REST_TRANSLATION: 'Rest',
  PRODUCT_REST_DESCRIPTION:
    "With a rest ticket you will be provided with a comfortable armchair that will ensure a relaxing journey in the train's relaxation wagon. Snackbar and fresh coffee is included.",
  PRODUCT_SLEEP_TRANSLATION: 'Sleep',
  PRODUCT_SLEEP_DESCRIPTION:
    'Travel in your own sleeping compartment - a compartment is comprised of two made up beds, each 75 cm wide.',
  GOA_SINGLE_TICKET_NAME_TRANSLATION: 'Single Ticket',
  GOA_SINGLE_TICKET_NAME_WARNING: 'Be mindful that these tickets cannot be changed or refunded',
  GOA_NO_ANIMAL_FREE_TICKET_WARNING: 'There are no animal free spaces on local trains',
  GOA_CONSCRIPT_TICKET_WARNING: 'The discount for conscripts only applies on standard tickets.',
  GOA_BIKE_TICKET_WARNING:
    "The bike can't be taken with you to the seat, but placed in their own spot for bikes. The train personnel will help you.",
  TRAIN: 'Train',
  MORE_INFO: 'More info',
  LAST_UPDATED_AT: 'Last updated',
  DESTINATION: 'Destination',
  ORIGIN: 'Origin',
  TOGGLE_DIRECTION: 'Toggle direction',
};
