import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import {
  TxtLargeMediumDarkResp,
  TxtDefaultDarkGreyResp,
  TxtLargeBoldLightResp,
  TxtSmallMediumAccentResp,
} from 'elements/new-design/Typography';
import { TxtLinkDefaultBrown } from 'elements/links/Links';
import { Config } from 'utils/Config';
import { FlexBetweenFullWidth, FlexColumn, FlexEnd, FlexRowStart } from 'elements/containers/Containers';
import { BusForTrain } from './BusForTrain';
import { getTrainLineFromProducLineId } from 'utils/Helpers';
import { MarginRight } from 'elements/distance/Margins';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Constants } from 'utils/Constants';

type CriticalDeviationProps = {
  deviation: any;
};

type StyleProps = {
  isCritical: boolean;
};

type TrainLineProps = {
  trainLine: string;
};

const DeviationContainer = styled.div`
  ${(_: StyleProps) => ''}
  margin-bottom: 2.4rem;
  box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
  border-radius: 2rem;
`;

const TrainLine = styled.div`
  ${(_: TrainLineProps) => ''}
  border-top-left-radius: 2rem;
  background-color: ${(props) =>
    props.trainLine === 'F5'
      ? props.theme.newcolors.bgBlue
      : props.trainLine === 'R50'
      ? props.theme.newcolors.bgRed
      : props.theme.newcolors.bgGreen};
  padding: 0.5rem 1.5rem;
  align-self: flex-start;
`;

const FromTo = styled(FlexBetweenFullWidth)`
  padding: 0.5rem 1.5rem;
  align-items: end;
`;

const PlannedOngoingHeader = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const PlannedOngoingSubHeader = styled(FlexRowStart)<{ hasReplacements: boolean }>`
  width: ${(props) => (props.hasReplacements ? '100%' : '60%')};
`;

const DeviationText = styled(TxtSmallMediumAccentResp)`
  display: flex;
  padding: 1rem 1.5rem;
  align-self: flex-start;
  font-weight: normal;
`;

const ReadMore = styled(TxtLinkDefaultBrown)`
  display: flex;
  justify-content: end;
  padding: 0.5rem 1.5rem;
`;

const getDaysShort = (weekDays: string[], lang) =>
  weekDays.reduce((acc, curr) => {
    let day = curr.substring(0, 3).toLowerCase();
    if (lang !== 'no') day = capitalizeFirstLetter(day);
    acc.push(day);
    return acc;
  }, []);

const capitalizeFirstLetter = (stringToCapitalize: string) =>
  stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1).toLocaleLowerCase();

/// Handled planned (future) and ongoing deviations
export const PlannedOngoingDeviation = ({ deviation }: CriticalDeviationProps): JSX.Element => {
  const { currentLanguage, translate } = useI18n();
  const { device } = useBreakpoints();
  const BUS_FOR_TRAIN_URL = Config().busForTrainUrl;

  const handleDays = () => {
    if (deviation.weekDays.no.length === 1) {
      return currentLanguage === 'no'
        ? `${translate('ONLY_VALID')} ${deviation.weekDays.no.join(', ').toLocaleLowerCase()}`
        : `${translate('ONLY_VALID')} ${capitalizeFirstLetter(deviation.weekDays.en.join(', '))}`;
    } else {
      const isDaysInLine = (weekDays: string[]) => {
        const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Satuday', 'Sunday'];
        let dayIndex = dayOrder.indexOf(weekDays[0]);
        for (let i = 1; i < weekDays.length - 1; i++) {
          const dayIndexNext = dayOrder.indexOf(weekDays[i]);
          const expectedNext = dayIndex == 6 ? 0 : dayIndex + 1;
          if (expectedNext != dayIndexNext) return false;
          dayIndex = expectedNext;
        }
        return true;
      };
      const days = currentLanguage === 'no' ? deviation.weekDays.no : deviation.weekDays.en;
      if (isDaysInLine(deviation.weekDays.en)) {
        const dayRange = `${getDaysShort(days, currentLanguage)[0]}
            - ${getDaysShort(days, currentLanguage)[days.length - 1]}`;
        return `${translate('APPLIES_FOR')} ${dayRange}`;
      } else {
        const dayRange = getDaysShort(days, currentLanguage).join(', ');
        return `${translate('APPLIES_FOR')} ${dayRange}`;
      }
    }
  };

  const handleDeviationTimeJsx = () => {
    if (!deviation.withDailyTimeSlot) {
      return (
        <FlexColumn>
          <TxtDefaultDarkGreyResp>
            {`${deviation.fromDateTime.formatDateFormatSemiLong(
              currentLanguage,
            )} - ${deviation.untilDateTime.formatDateFormatSemiLong(currentLanguage)}`}
          </TxtDefaultDarkGreyResp>
        </FlexColumn>
      );
    } else {
      return (
        <FlexColumn>
          <TxtDefaultDarkGreyResp>
            {`${deviation.fromDateTime.formatDateFormatLongMonthDayOnly(
              currentLanguage,
            )} - ${deviation.untilDateTime.formatDateFormatLongMonthDayOnly(currentLanguage)}`}
          </TxtDefaultDarkGreyResp>
          {deviation.weekDays && <TxtDefaultDarkGreyResp> {`${handleDays()}`}</TxtDefaultDarkGreyResp>}
          {deviation.withDailyTimeSlot && (
            <TxtDefaultDarkGreyResp>{`${translate(
              'BETWEEN',
            )} ${deviation.fromDateTime.formatTime()} - ${deviation.untilDateTime.formatTime()}`}</TxtDefaultDarkGreyResp>
          )}
        </FlexColumn>
      );
    }
  };

  return (
    <DeviationContainer isCritical={deviation.messageAlert}>
      <PlannedOngoingHeader>
        <PlannedOngoingSubHeader hasReplacements={deviation.hasReplacements}>
          <TrainLine trainLine={getTrainLineFromProducLineId(deviation.affectedProductLineIds[0])}>
            <TxtLargeBoldLightResp>
              {getTrainLineFromProducLineId(deviation.affectedProductLineIds[0])}
            </TxtLargeBoldLightResp>
          </TrainLine>
          {deviation.hasReplacements && (
            <FlexEnd>
              <MarginRight margin={1.5}>
                <BusForTrain deviationId={deviation.situationId} />
              </MarginRight>
            </FlexEnd>
          )}
        </PlannedOngoingSubHeader>

        {device === Constants.DEVICE_XSMALL && handleDeviationTimeJsx()}

        <FromTo>
          <TxtLargeMediumDarkResp>
            {deviation.stationFrom} - {deviation.stationTo}
          </TxtLargeMediumDarkResp>
          {device !== Constants.DEVICE_XSMALL && handleDeviationTimeJsx()}
        </FromTo>
      </PlannedOngoingHeader>

      <DeviationText>
        {currentLanguage === 'no' ? deviation.messageDescription.no : deviation.messageDescription.en}
      </DeviationText>

      <ReadMore href={`${BUS_FOR_TRAIN_URL}${deviation.situationId}`} target="_blank" rel="noreferrer">
        {translate('READ_MORE')}
      </ReadMore>
    </DeviationContainer>
  );
};
